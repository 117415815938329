<template>
  <div>
    <b-card no-body>
      <b-table
        :busy="loading"
        show-empty
        :items="leads"
        :fields="fields"
        striped
        responsive
      >
        <template #cell(show_details)="row">
          <b-form-checkbox
            v-model="row.detailsShowing"
            plain
            class="vs-checkbox-con"
            @change="row.toggleDetails"
          >
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                <i class="vs-icon feather icon-check" />
              </span>
            </span>
            <span class="vs-label">{{
              row.detailsShowing ? "Hide" : "Show"
            }}</span>
          </b-form-checkbox>
        </template>

        <template #row-details="row">
          <b-card>
            <b-row class="mb-2">
              <b-col md="4" class="mb-1">
                <strong>Full Name : </strong>{{ row.item.user.name }}
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>Mobile Number : </strong>{{ row.item.user.phone }}
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>Total Amount : </strong>Rs
                {{ formatPrice(row.item.total) }}
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>Brand : </strong>{{ row.item.brand.name }}
                <div class="mt-1">
                  <strong>Last Updated : </strong
                  >{{ getFormattedTime(row.item.updatedAt) }}
                </div>
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>Model : </strong>{{ row.item.model.name }}
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>Issues </strong>
                <div>
                  <b-badge
                    variant="light-primary"
                    :class="index != 0 ? 'ml-1' : ''"
                    v-for="(issue, index) in row.item.issues"
                    :key="issue._id"
                  >
                    {{ issue.issue.name }} - {{ formatPrice(issue.price) }}
                  </b-badge>
                </div>
              </b-col>
            </b-row>

            <b-button
              size="sm"
              :to="`/leads/${row.item._id}`"
              variant="dark"
              @click="row.toggleDetails"
            >
              View More Details
            </b-button>
            <b-button
              size="sm"
              class="ml-1"
              variant="primary"
              @click="row.toggleDetails"
            >
              Convert To Order
            </b-button>
            <b-button class="ml-1" size="sm" variant="outline-success"
              >View Logs</b-button
            >
          </b-card>
        </template>

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>Loading data...</strong>
          </div>
        </template>
        <template #empty>
          <div class="text-center">
            <b-col cols="4" class="mx-auto my-2">
              <h4>No Data Found</h4>
              <feather-icon size="32" icon="AlertTriangleIcon"></feather-icon>
            </b-col>
          </div>
        </template>

        <template #cell(order_id)="data">
          <b-badge variant="light-warning">
            {{ data.item.orderID }}
          </b-badge>
        </template>

        <template #cell(name)="data">
          {{ data.item.user.name }}
        </template>

        <template #cell(brand)="data">
          {{ data.item.brand.name }}
        </template>

        <template #cell(model)="data">
          {{ data.item.model.name }}
        </template>

        <template #cell(total)="data">
          <b-badge variant="light-primary">
            Rs {{ formatPrice(data.item.total) }}
          </b-badge>
        </template>

        <template #cell(created_at)="data">
          {{ getFormattedTime(data.item.createdAt) }}
        </template>
      </b-table>

      <b-card-footer v-if="leads.length">
        <b-pagination
          :per-page="perPage"
          v-model="page"
          :total-rows="rows"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import {
  BTable,
  BFormCheckbox,
  BButton,
  BCard,
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BCardFooter,
  BPagination,
  BSpinner,
} from "bootstrap-vue";
import { getUserToken } from "@/auth/utils";
import axios from "axios";
import moment from "moment-timezone";
import commaNumber from "comma-number";

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BCardFooter,
    BPagination,
    BSpinner,
  },
  data() {
    return {
      fields: [
        "show_details",
        "order_id",
        "name",
        "brand",
        "model",
        "total",
        "mode",
        "created_at",
      ],
      leads: [],
      page: 1,
      rows: 8,
      perPage: 15,
      loading: true,
    };
  },
  watch: {
    page() {
      this.getLeads();
    },
  },
  methods: {
    getLeads() {
      this.loading = true;
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/order?status=draft&page=${this.page}&limit=${this.perPage}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.leads = response.data.leads;
          this.rows = response.data.count;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response.data);
          this.loading = false;
        });
    },
    getFormattedTime(time) {
      return moment(time)
        .tz("Asia/Kolkata")
        .format("DD-MM-YYYY, h:mm a");
    },
    formatPrice(price) {
      return commaNumber(price);
    },
  },
  mounted() {
    this.getLeads();
  },
};
</script>
